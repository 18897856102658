import { Dialog } from 'vant'

let globalData = null
let map = null
let floorControl = null
let navi = null

let from, to

let ctlOpt = new esmap.ESControlOptions({
  position: esmap.ESControlPositon.RIGHT_TOP,
  // 位置x,y的偏移量
  offset: {
    x: 0,
    y: 100
  },
  showBtnCount: 3, // 显示按钮个数
  textColor: '#000', // 字体颜色
  toggleColor: '#1F6ED4', // 单层多层按钮和2d3d按钮颜色
  bgColor: '#FFF', // 背景颜色
  borderRadius: 5, // 圆角
  focusTextColor: '#FFF', // 聚焦楼层字体颜色
  focusBgColor: '#1F6ED4', // 聚焦楼层背景颜色
  scale: 1 // 放大比例
})

export function initMap(data) {
  globalData = data
  let container = document.getElementById('container')
  map = new esmap.ESMap({
    container: container, // 渲染dom
    mapDataSrc: './data', // 地图数据位置
    mapThemeSrc: './data/theme', // 主题数据位置
    visibleFloors: 'all', // 更多初始化参数配置请参考https://www.esmap.cn/escopemap/content/cn/develope/map-config.html
    token: 'escope',
    mapAudioSrc: './',
    focusAlphaMode: false
  })

  // 打开地图数据
  map.openMapById('test666')
  map.showCompass = true // 显示指南针

  onMapEvent(map, data)
}

function onMapEvent(map, data) {
  map.on('loadComplete', function () {
    // 创建楼层控件
    floorControl = new esmap.ESScrollFloorsControl(map, ctlOpt)
    initNavi()
  })

  // 点击地图事件。开始选点开始后，点击地图一次为起点，第二次点击为终点
  map.on('mapClickNode', function (event) {
    globalData.isShowNode = true
    console.log('当前节点', event)
    // if (event.nodeType == 4) {
    //   curfnum = event.floor
    //   h = 1
    //   mapCoord = event.hitCoord
    // }
    if (event.nodeType === 5) {
      globalData.curNode = event
    }
  })
}

function initNavi() {
  // 初始化导航对象
  navi = new esmap.ESNavigation({
    map: map,
    locationMarkerUrl: 'image/pointer.png',
    locationMarkerSize: 150,
    speed: 1,
    followAngle: true,
    followPosition: true,
    followGap: 0,
    tiltAngle: 30,
    audioPlay: true,
    // scaleLevel:0,
    mode: 1,
    offsetHeight: 1,
    maxEnd: 1, // 离终点1米时候就结束导航
    // 设置导航线的样式
    lineStyle: {
      color: '#58a2e4',
      // 设置线为导航线样式
      lineType: esmap.ESLineType.ESARROW,
      // lineType: esmap.ESLineType.FULL,
      lineWidth: 6,
      fixedWidth: true,
      offsetHeight: 0.5,
      smooth: true,
      seeThrough: false,
      noAnimate: true
      // 设置边线的颜色
      // godEdgeColor: '#920000',
      // 设置箭头颜色
      // godArrowColor: "#ffffff"
    },
    scaleAnimate: true,
    isMultiFloors: false
  })
  console.log(navi)
  onNaviEvent(navi)
}

function onNaviEvent(nvai) {
  let log = function (time) {
    let flag = true
    return function (e) {
      if (flag) {
        console.log(e)
        flag = false
        setTimeout(() => {
          flag = true
        }, time)
      }
    }
  }
  let fun = log(2000)
  navi.on('walking', function (e) {
    fun(e)
  })

  navi.on('naviTip', function (message) {
    message.replace('undefined', '上电梯')
    globalData.isTip = true
    globalData.tipContext = message
  })

  navi.on('complete', function () {
    globalData.isTip = false
    navi.clearAll()
    Dialog.alert({
      title: '导航结束',
      message: '本次导航共计用时10s'
    }).then(() => {
      // on close
    })
  })
}

function setStartNode(node) {
  from = globalData.curNode
  navi.setStartPoint({
    x: from.hitCoord.x,
    y: from.hitCoord.y,
    fnum: from.FloorNum,
    height: from.RoomHigh,
    url: 'image/start.png',
    size: 64,
    showLevel: 23 // 配置起点图标缩放等级,一直显示(默认)
  })
}

function setEndNode(node) {
  to = globalData.curNode
  navi.setEndPoint({
    x: to.hitCoord.x,
    y: to.hitCoord.y,
    fnum: to.FloorNum,
    height: to.RoomHigh,
    url: 'image/end.png',
    size: 64,
    showLevel: 23 // 配置起点图标缩放等级,一直显示(默认)
  })
  navi.drawNaviLine()
}

function startMove() {
  navi.followAngle = true
  navi.followPosition = true
  navi.scaleAnimate = true
  // if (navi.isSimulating) {
  //   navi.stop()
  //   navi.reset()
  // }
  // 下句解决IOS苹果手机不能播放问题，不能删除
  // map.ESAudioTool.playSoundByName('startNaving')

  navi.simulate()
}

export function mapMethods() {
  return {
    setStartNode,
    setEndNode,
    startMove
  }
}
