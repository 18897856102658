<template>
  <div class="page">
    <!-- <map-floor
      v-model="floor"
      :floorList="floors"
      class="floor_contral"
    ></map-floor> -->
    <div class="tip" v-if="isTip">{{ tipContext }}</div>
    <!-- <h1 @click="test">小程序端地图</h1> -->
    <div id="container"></div>
    <van-dialog></van-dialog>
    <van-popup
      v-model:show="isShowNode"
      teleport="body"
      position="bottom"
      :style="{ height: '15%' }"
      round
      :overlay="false"
      :close-on-click-overlay="false"
      :lock-scroll="false"
    >
      <div class="popup_con">
        <van-row gutter="30" justify="center">
          <van-col>
            <div style="padding-top: 20px">
              <van-button
                plain
                type="primary"
                @click="setStartNode"
                size="small"
              >
                设为起点
              </van-button>
            </div>
          </van-col>
          <van-col>
            <div class="to">{{ curNode.name }}</div>
            <div style="display: flex; justify-content: center">
              <van-button
                round
                type="primary"
                @click="startMove"
                color="linear-gradient(to right, #ff6034, #ee0a24)"
              >
                开始导航
              </van-button>
            </div>
          </van-col>
          <van-col>
            <div style="padding-top: 20px">
              <van-button plain type="primary" @click="setEndNode" size="small">
                设为终点
              </van-button>
            </div>
          </van-col>
        </van-row>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
// import MapFloor from './map-floor.vue'
import { initMap, mapMethods } from './map'
export default {
  data() {
    return {
    }
  },
  setup(props, ctx) {
    // 存储map相关响应式对象
    const data = reactive({
      // 当前选择的点
      curNode: null,
      // 是否弹窗选择显示当前节点信息
      isShowNode: false,

      isTip: false,

      tipContext: ''
    })

    // 在Mounted时间来初始化地图对象
    onMounted(() => initMap(data))

    return {
      ...toRefs(data),
      ...mapMethods()
    }
  },

  methods: {
    test() {
      // console.log(this.showPopup)
      // this.showPopup()
    }
  }
}
</script>

<style scoped>
/**
 * 页面相关样式
*/

.page {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
}

.page > h1 {
  padding: 5px 0;
  text-align: center;
}

#container {
  height: 100%;
}

/**
 * 控制器相关样式
*/

.floor_contral {
  position: absolute;
  right: 10px;
  bottom: 18%;
}

.tip {
  position: absolute;
  width: 70%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: bold;
  font-size: large;
  border-radius: 10px;
  top: 80px;
  transform: translateX(-50%);
  left: 50%;
}

.popup_con {
  padding-top: 10px;
}
.to {
  text-align: center;
  font-weight: bold;
  font-size: xx-large;
  padding-bottom: 15px;
}
</style>
